import {
  createElement,
  forwardRef,
  FC,
  PropsWithoutRef,
  PropsWithRef
} from 'react';
import {
  RightIcon,
  RightIconCCM,
  Input,
  InputCCM,
  Label,
  LabelCCM,
  Messages,
  MessagesCCM
} from './text-input.ccm.css';
import { useTextInputTheme } from './text-input.theme';

export const ThemedInput: FC<
  Partial<InputCCM> & PropsWithRef<JSX.IntrinsicElements['input']>
> = forwardRef((props, ref) => {
  const {
    base: { borderColor, inputColor, backgroundColor },
    empty,
    error,
    filled,
    focused,
    warn,
    readonly,
    caretColor,
    borderRadius,
    backgroundColorNew
  } = useTextInputTheme();
  return (
    <Input.input
      ref={ref as any}
      $backgroundColor={backgroundColorNew}
      $borderRadius={borderRadius}
      $filledBorderColor={filled.borderColor || borderColor}
      $filledInputColor={filled.inputColor || inputColor}
      $filledBackgroundColor={filled.backgroundColor || backgroundColor}
      $emptyBorderColor={empty.borderColor || borderColor}
      $emptyInputColor={empty.inputColor || inputColor}
      $emptyBackgroundColor={empty.backgroundColor || backgroundColor}
      $focusedBorderColor={focused.borderColor || borderColor}
      $focusedInputColor={focused.inputColor || inputColor}
      $focusedBackgroundColor={focused.backgroundColor || backgroundColor}
      $warnBorderColor={warn.borderColor || borderColor}
      $warnInputColor={warn.inputColor || inputColor}
      $warnBackgroundColor={warn.backgroundColor || backgroundColor}
      $errorBorderColor={error.borderColor || borderColor}
      $errorInputColor={error.inputColor || inputColor}
      $errorBackgroundColor={error.backgroundColor || backgroundColor}
      $readonlyBorderColor={readonly.borderColor || borderColor}
      $readonlyInputColor={readonly.inputColor || inputColor}
      $readonlyBackgroundColor={readonly.backgroundColor || backgroundColor}
      $caretColor={caretColor}
      {...props}
    />
  );
});

export const ThemedLabel: FC<
  Partial<LabelCCM> & PropsWithoutRef<JSX.IntrinsicElements['span']>
> = (props) => {
  const {
    base: { labelColor },
    empty,
    error,
    filled,
    focused,
    warn,
    readonly
  } = useTextInputTheme();
  return (
    <Label.span
      $filledLabelColor={filled.labelColor || labelColor}
      $emptyLabelColor={empty.labelColor || labelColor}
      $focusedLabelColor={focused.labelColor || labelColor}
      $warnLabelColor={warn.labelColor || labelColor}
      $errorLabelColor={error.labelColor || labelColor}
      $readonlyLabelColor={readonly.labelColor || labelColor}
      {...props}
    />
  );
};

export const ThemedMessages: FC<
  Partial<MessagesCCM> &
    PropsWithoutRef<JSX.IntrinsicElements['div']> & {
      showValidationSpacing: boolean;
    }
> = ({ showValidationSpacing, ...props }) => {
  const {
    base: { messageColor },
    empty,
    error,
    filled,
    focused,
    warn,
    readonly,
    field
  } = useTextInputTheme();
  return (
    <Messages.div
      $showValidationSpacing={
        field === 'username' || field === 'cvv'
          ? showValidationSpacing
            ? 'auto'
            : 'auto'
          : showValidationSpacing
          ? '18px'
          : 'auto'
      }
      $filledMessageColor={filled.messageColor || messageColor}
      $emptyMessageColor={empty.messageColor || messageColor}
      $focusedMessageColor={focused.messageColor || messageColor}
      $warnMessageColor={warn.messageColor || messageColor}
      $errorMessageColor={error.messageColor || messageColor}
      $readonlyMessageColor={readonly.messageColor || messageColor}
      {...props}
    />
  );
};

export const ThemedRightIcon: FC<
  Partial<RightIconCCM> & PropsWithoutRef<JSX.IntrinsicElements['svg']>
> = (props) => {
  const { errorIconColor, readonlyIconColor } = useTextInputTheme();
  return (
    <RightIcon.svg
      $readonlyIconColor={readonlyIconColor}
      $errorIconColor={errorIconColor}
      {...props}
    />
  );
};
