import { createUseThemeHook } from 'legos/theme';

declare module 'legos/theme' {
  interface BulkLegoTheme {
    textInput: TextInputTheme;
  }
}

export interface TextInputThemeState {
  labelColor: string;
  borderColor: string;
  backgroundColor: string;
  inputColor: string;
  messageColor: string;
}

export interface TextInputTheme {
  base: TextInputThemeState;
  empty: Partial<TextInputThemeState>;
  filled: Partial<TextInputThemeState>;
  focused: Partial<TextInputThemeState>;
  warn: Partial<TextInputThemeState>;
  error: Partial<TextInputThemeState>;
  readonly: Partial<TextInputThemeState>;
  largeInput: Partial<TextInputThemeState>;
  readonlyIconColor: string;
  errorIconColor: string;
  caretColor: string;
  //This is added to add border radius
  borderRadius: string;
  backgroundColorNew: string;
  field: string;
}

const [TextInputThemeProvider, useTextInputTheme] = createUseThemeHook(
  'textInput',
  (global): TextInputTheme => ({
    // The defaults for when any states do not define values
    base: {
      backgroundColor: 'transparent',
      labelColor: global.neutral.color700,
      borderColor: global.neutral.color500,
      inputColor: global.neutral.color800,
      messageColor: global.neutral.color800
    },
    empty: {},
    readonly: {
      backgroundColor: global.neutral.color100,
      borderColor: global.neutral.color100
    },
    focused: {
      labelColor: global.default.color500,
      borderColor: global.default.color500
    },
    filled: {
      labelColor: global.neutral.color500
    },
    warn: {
      labelColor: global.default.color500,
      borderColor: global.default.color500,
      messageColor: global.negative.color500
    },
    error: {
      labelColor: global.negative.color500,
      borderColor: global.negative.color500,
      messageColor: global.negative.color500
    },
    largeInput: {},
    readonlyIconColor: global.neutral.color400,
    errorIconColor: global.negative.color500,
    caretColor: global.neutral.color800,
    borderRadius: '4px',
    backgroundColorNew: 'white',
    field: ''
  })
);

export { TextInputThemeProvider, useTextInputTheme };
